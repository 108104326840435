* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth !important;
  overflow-x: hidden !important;
}

body {
  margin-top: 0 !important;
  padding-top: 0 !important;
  font-family: 'Simpson', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: black;
  overflow-x: hidden !important;
}


@font-face {
  font-family: Simpson;
  src: url("../../3.images/simpson.ttf");
}